div table tbody tr #gift {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
}

div table tbody tr td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.bg-tree{
  /* border: 1px solid #bdbdbd; */
  /* background-image: url(../../assets/fontawesome/image/bg-tree.png);
  background-position: contain;
  width: 100%;
  height: 300px;
  background-size: 100% 100%; */
}
.border-bg-umx{
  border: 1px solid #1f5f18;
  border-radius: 10px;
}